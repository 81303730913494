const OrigemAnexoEnum = Object.freeze({
    MANUAL_SISTEMA: 'MANUAL_SISTEMA',
    CHECKLIST_INICIAL: 'CHECKLIST_INICIAL',
    CHECKLIST_AMBIENTE: 'CHECKLIST_AMBIENTE',
    CHECKLIST_PERIGO: 'CHECKLIST_PERIGO',
    CHECKLIST_PERIGO_FONTE_GERADORA_MEDICAO: 'CHECKLIST_PERIGO_FONTE_GERADORA_MEDICAO',
    CHECKLIST_PERIGO_MEDIDA_CONTROLE_EXISTENTE: 'CHECKLIST_PERIGO_MEDIDA_CONTROLE_EXISTENTE',
    APLICACAO_LEVANTAMENTO_CONCLUSAO: 'APLICACAO_LEVANTAMENTO_CONCLUSAO',
    RELATORIO_VISITA_TECNICA: 'RELATORIO_VISITA_TECNICA',
    RELATORIO_GHE: 'RELATORIO_GHE'
});

export default OrigemAnexoEnum;
